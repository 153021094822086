// src/App.jsx
import React from "react";
import bwsLogo from "./assets/bws-logo.webp";
import appStore from "./assets/apple-store.svg";
import googlePlay from "./assets/play-store.webp";
import appImage from "./assets/app.png";

function App() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white">
      <header>
        <img
          src={bwsLogo}
          alt="Logo"
          className="min-w-24 max-w-72 md:max-w-96 mx-auto mr-8"
        />
      </header>
      <main className="container mx-auto p-4 flex justify-center items-center">
        <div>
          <img
            src={appImage}
            alt="BWS App"
            className="min-w-24 max-w-64 md:max-w-72 mx-auto"
          />
          <h1 className="text-lg md:text-2xl font-bold text-center">
            Download the BWS App below!
          </h1>
          <div className="flex gap-0 flex-row justify-between items-center my-4">
            <div className="flex-1 justify-center items-center">
              <a
                href={
                  "https://apps.apple.com/ca/app/built-with-science/id6446000532"
                }
                className="flex flex-col justify-center items-center"
              >
                <img src={appStore} className={"w-16 h-16"} alt="Apple Store"/>
                <p>App Store</p>
              </a>
            </div>
            <div className="flex-1 self-center">
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.bws.appv2"
                }
                className="flex flex-col justify-center items-center"
              >
                <img src={googlePlay} className={"w-16 h-16"} alt="Google Play Store"/>
                <p>Google Play</p>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="text-white p-4 w-full text-center"></footer>
    </div>
  );
}

export default App;
